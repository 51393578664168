import React from 'react'
import { Link } from 'react-router-dom'
import '../Franchise/Franchise.css'
import { FaDownload } from "react-icons/fa";



export default function Franchisescreen() {
  return (
    <>
     <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>PCD Pharma Franchise</li>
            </ul>
            <h1>PCD Pharma Franchise</h1>
          </div>
        </div>
      </section>
      <section className="service-details-page sec-pad">
    <div className="auto-container">
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="service-details-sidebar-content">
                    
                    <div className="service-details-box mb_35">
                        <h3>Our Special Category</h3>
                        <ul className="services-categories">
                        <li><Link to="/tablets">Tablets</Link></li>
                                        <li><Link to="/syrup">Syrup</Link></li>
                                        <li><Link to="/suspension">Suspension</Link></li>
                                        <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                        <li><Link to="/injections">Injections</Link></li>
                                        <li><Link to="/respules">Respules</Link></li>
                                        <li><Link to="/ivee">Ivee</Link></li>
                                        <li><Link to="/sachet">Sachet</Link></li>
                                        <li><Link to="/drops">Drop</Link></li>
                                        <li><Link to="/gels">Gels</Link></li>
                                        <li><Link to="/oil">Oil</Link></li>
                                        <li><Link to="/powder">Powder</Link></li>
                                        <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                        <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                                       
                                         {/* Stylish PDF Download Buttons */}
                                        <li>
                                        <a href="/pdfs/opthalmic.pdf" download>
                                            <button className="download-btn">
                                            <FaDownload className="icon" /> Download Opthalmic PDF
                                            </button>
                                        </a>
                                        </li>

                                        <li>
                                        <a href="/pdfs/ortho_pdf.pdf" download>
                                            <button className="download-btn">
                                            <FaDownload className="icon" /> Download Ortho PDF
                                            </button>
                                        </a>
                                        </li>

                                        <li>
                                        <a href="/pdfs/Launched.xls" download>
                                            <button className="download-btn">
                                            <FaDownload className="icon" /> Download Launched Excel
                                            </button>
                                        </a>
                                        </li>
                        </ul>
                    </div>
                   
                    <div className="service-details-box two mb_35" style={{backgroundImage: "url(assets/images/project/service-details.jpg)",}}>
                        <h3>Contact Us <br />
                            For Any Require</h3>
                        <div className="icon-box">
                            <div className="icon">
                                <i className="flaticon-phone"></i>
                            </div>
                            <div className="info">
                                <span>Need help?  Call us:</span>
                                <h4><Link to="tel:+(91)-8146674934">+(91)-8146674934</Link></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12">
                <div className="service-details-content">
                    <figure className="image">
                        <img src="assets/images/Best PCD Pharma Franchise.png" alt="" 
                        
                        />
                    </figure>
                    <h2 className="service-details-title">Best Pharma Franchise Company</h2>
                    <p className="service-details-text-1 mb_20">Biomax Biotechnics Private Limited is proud to offer an exceptional PCD Pharma Franchise opportunity to individuals and organizations seeking to join hands with a pioneering biotechnology company. Our PCD Pharma Franchise program is designed to empower partners with high-quality pharmaceutical products, extensive support, and a pathway to success in the pharmaceutical industry.</p>
                   <p>Starting a PCD Pharma Franchise with Biomax Biotechnics Private Limited is a straightforward and rewarding process. To explore this exciting opportunity, simply get in touch with us through the provided contact details. Our dedicated team will guide you through the application process, discuss partnership terms, and address any queries you may have.</p>
                    <p>Unlock your potential and embark on a journey of healthcare excellence with Biomax Biotechnics Private Limited's PCD Pharma Franchise. Together, we can make a positive impact on healthcare delivery and contribute to the well-being of communities.

Contact us today to learn more about our PCD Pharma Franchise opportunity and take the first step toward a successful and fulfilling partnership.</p>
                    <div className="service-details-icon-box">
                        <div className="row g-4">
                          
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="faq service-icon-box">
                                    <div className="icon-box">
                                        <div className="icon-box-inner">
                                            <i className="flaticon-task-list"></i>
                                        </div>
                                        <h5><Link to="#">Innovative Product Portfolio</Link></h5>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="faq service-icon-box">
                                    <div className="icon-box">
                                        <div className="icon-box-inner">
                                            <i className="flaticon-task-list"></i>
                                        </div>
                                        <h5><Link to="#">Quality Assurance</Link></h5>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="faq service-icon-box">
                                    <div className="icon-box">
                                        <div className="icon-box-inner">
                                            <i className="flaticon-task-list"></i>
                                        </div>
                                        <h5><Link to="#">Marketing and Promotional Support</Link></h5>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="faq service-icon-box">
                                    <div className="icon-box">
                                        <div className="icon-box-inner">
                                            <i className="flaticon-task-list"></i>
                                        </div>
                                        <h5><Link to="#">Training and Education</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>                         
                    </div>  
                   
                </div>
            </div>
        </div>
    </div>
</section>

      </>
  )
}
