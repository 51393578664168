import React from 'react'
import {Link} from 'react-router-dom'

export default function Footer() {
  return (
    <>
   
        <footer className="main__footer" style={{backgroundImage: "url(assets/images/resource/map.png)",}}>
            <div className="widget__section">
                <div className="auto-container">
                    <div className="news__letter">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="news__letter__left">
                                    <div className="news__icon">
                                        <i className="flaticon-phone"></i>
                                    </div>
                                    <div className="news__text">
                                        <h3>Call</h3>
                                        <p>Get a Pharmaceutical Products at affroadable rate</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                               <div className="text-rights">
                                   <div className="btn-box">
                                       <Link to="tel:+(91)-8146674934" className="theme-btn btn-two">+(91)-8146674934 </Link>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__middel___content pb_35">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="question">
                                    <span>Have Any Question?</span>
                                    <h3><Link to="tel: +(91)-8146674934 "> +(91)-8146674934 </Link></h3>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer__logo text-center">
                                    <figure>

                                        <h3><Link to="tel: +(91)-8146674934 "> </Link></h3>
                                    </figure>
                                    <div className="question">
                                        <span>BBPL</span>
                                        <h3><Link to="tel:+91 8146674934">Biomax Biotechnics Private Limited </Link></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="question text-right">
                                    <div className="question___data">
                                        <span>Have Any Question?</span>
                                        <h3><Link to="mailto:care@biomaxbiotechnics.in ">care@biomaxbiotechnics.in </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer___main__content pt_50 pb_60">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3  col-sm-12 footer-column">
                                <div className="footer__text">
                                    <p>A leading manufacturer and supplier of DCGI Approved formulations such as
                                        tablets, injections and new drugs, Biomax Biotechnics Pvt. Ltd., has etched a
                                        position for itself across the Indian pharmaceutical market.</p>
                                    <div className="footer__social__media">
                                        <ul>
                                            <li><Link to="#"> <i className="flaticon-linkedin"></i></Link></li>
                                            <li><Link to="#"> <i className="flaticon-facebook"></i></Link></li>
                                            <li><Link to="#"> <i className="flaticon-twitter"></i></Link></li>
                                            <li><Link to="#"> <i className="flaticon-dribbble"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div className="footer__middle__link">
                                    <div className="links__widget__link">
                                        <div className="widget-title">
                                            <h3>Explore</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links-list clearfix">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/about-us">About</Link></li>
                                                <li><Link to="/pcd-pharma-fanchise">PCD Pharma Fanchise</Link></li>
                                                <li><Link to="/contact-us">Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div className="footer__middle__link">
                                    <div className="links__widget__link">
                                        <div className="widget-title">
                                            <h3>What we offer</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links-list clearfix">
                                            <li><Link to="/tablets">Tablets</Link></li>
                                            <li><Link to="/syrup">Syrup</Link></li>
                                            <li><Link to="/suspension">Suspension</Link></li>
                                            <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                            <li><Link to="/injections">Injections</Link></li>
                                            <li><Link to="/respules">Respules</Link></li>
                                            <li><Link to="/ivee">Ivee</Link></li>
                                            <li><Link to="/sachet">Sachet</Link></li>
                                            
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget schedule-widget ">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13742.94004432416!2d76.9398629!3d30.55679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f9331ad749419%3A0x265c0b0e6f7b1976!2sBiomax%20Biotechnics!5e0!3m2!1sen!2sin!4v1692169881292!5m2!1sen!2sin" width="100%" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom centred pt_20 pb_20">
                <div className="auto-container">
                    <div className="copyright">
                        <p>Copyright © 2023 <Link to="/">BBPL.</Link> All rights reserved. Developed & Designed by <Link to="https://rednirus.in/">Rednirus Digital Media</Link></p>
                    </div>
                </div>
            </div>
        </footer>
       
       
        
    </>
  )
}
