import { Link, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const BlogDetails = () => {
  const location = useLocation();
  const data = location.state || {};
  // const { bslug } = useParams();
  // console.log('Blog Slug' .slug);
  
  // const [blog, setBlog] = useState({});


  //    const blogs = () => {
  //         fetch(`https://dashboard.biomaxbiotechnics.in/api/blogs/${bslug}`).then((res) => res.json())
  //             .then((result) => {
  //                 setBlog(result.blogs)
  //                 console.log(result.blogs)
  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //             })
  //     }

  
  //     useEffect(() => {
  //         blogs()
  //     }, [bslug])


  

  // if (!blog) return <p>Loading...</p>;

  return (
    <>

<section
        className="page-title-section p_relative text-center"
        style={{ backgroundImage: `url("/assets/images/SHKAS.webp")` }}
      >
        <div
          className="shape-1"
          style={{ backgroundImage: `url("/assets/images/shape/shape-34.png")` }}
        ></div>
        <div
          className="shape-2"
          style={{ backgroundImage: `url("/assets/images/shape/shape-35.png")` }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
          <h1>{data.name}</h1>
            <ul className="bread-crumb clearfix">
              <li className="dropdown">
                <a href="/">Home</a>
              </li>
              <li>{data.name}</li>
            </ul>
           
          </div>
        </div>
      </section>



      <section className="py-10">
  <div className="container mx-auto px-4">
    <div className="row">
      {/* Blog Content - Left Section (col-md-8) */}
      <div className="col-md-8">
        <img
          src={`https://dashboard.biomaxbiotechnics.in/public/images/blog/${data?.image}`}
          alt={data?.name || "Blog Image"}
          className="img-fluid w-100 border "
        />

        {/* Title & Date */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <h1 className="h3 font-weight-bold">{data.name}</h1>
          <p className="text-muted small">
            {new Date(data.created_at).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </p>
        </div>

        {/* Blog Description */}
        <p className="mt-3 text-muted" style={{ fontSize: "1.1rem", lineHeight: "1.6" }}>
  {data.description?.replace(/<[^>]+>/g, '')}
</p>

      </div>

      {/* Sidebar - Right Section (col-md-4) */}
      <div className="col-md-4">
        <div class="sidebar">
        
                                        <div class="all_categorys">
                                            <h4>Dosage Form</h4>
                                            <ul>
                                                <li><Link to="/tablets">Tablets</Link></li>
                                                <li><Link to="/syrup">Syrup</Link></li>
                                                <li><Link to="/suspension">Suspension</Link></li>
                                                <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                                <li><Link to="/injections">Injections</Link></li>
                                                <li><Link to="/respules">Respules</Link></li>
                                                <li><Link to="/ivee">Ivee</Link></li>
                                                <li><Link to="/sachet">Sachet</Link></li>
                                                <li><Link to="/drops">Drop</Link></li>
                                                <li><Link to="/gels">Gels</Link></li>
                                                <li><Link to="/oil">Oil</Link></li>
                                                <li><Link to="/powder">Powder</Link></li>
                                                <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                                <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                                            </ul>
                                        </div>
        
                                        <div class="all_categorys">
                                            <h4>Therapeutic wise</h4>
                                            <ul>
                                                <li><Link to="/anti-allergy">Anti Allergy</Link></li>
                                                <li><Link to="/laxative">Laxative</Link></li>
                                                <li><Link to="/ortho">Ortho</Link></li>
                                                <li><Link to="/gastro">Gastro</Link></li>
                                                <li><Link to="/pediatric">Pediatric</Link></li>
                                                <li><Link to="/anti-diarreahea">Anti diarreahea</Link></li>
                                                <li><Link to="/dental">Dental</Link></li>
                                                <li><Link to="/respiratory">Respiratory</Link></li>
                                                <li><Link to="/antacid">Antacid</Link></li>
                                                <li><Link to="/gyne">Gynee</Link></li>
                                                <li><Link to="/cardiac">Cardiac</Link></li>
                                                <li><Link to="/diabetic">Diabetic</Link></li>
                                                <li><Link to="/neuro">Neuro</Link></li>
                                                <li><Link to="/urology">Urology</Link></li>
                                            </ul>
                                        </div>
                                        </div>
      </div>
    </div>
  </div>
</section>

      
     
      
   
    
    </>
  );
};

export default BlogDetails;
