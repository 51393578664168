import React from 'react'
import { Link } from 'react-router-dom'

export default function Vision() {
  return (
    <>
      <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>Vision / Mission / Value</li>
            </ul>
            <h1>Vision / Mission / Value</h1>
          </div>
        </div>
      </section>
    
      <section className="feature-section-3"  id="cert3">
        <div className="shape-1" style={{ backgroundImage: "url(assets/images/shape/shape-21.png)", }} ></div>
        <div className="auto-container">
          <div className="title-box text-center mb_60">



          </div>
          <div className="row g-4">

            <div className="col-lg-4 col-md-6 col-sm-12" >
              <div className="feature-box-3">
                <div className="inner">
                  <div className="icon-box">
                    <img src="/assets/images/opportunity.png" />
                  </div>
                  <h3>Our Vision</h3>
                  <p className="text">We aspire to lead the way in advancing scientific innovation, improving healthcare outcomes, and promoting sustainable practices for a healthier and more resilient world.</p>
                  

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="feature-box-3">
                <div className="inner">
                  <div className="icon-box">
                    <img src="/assets/images/mis.png" />
                  </div>
                  <h3>Our Mission</h3>
                  <p className="text">Our mission is to harness the power of biotechnology to create impactful solutions that address critical challenges in healthcare, agriculture, and the environment.</p>
                 

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="feature-box-3">
                <div className="inner">
                  <div className="icon-box">
                    <img src="/assets/images/val.png" />
                  </div>
                  <h3>Our Value </h3>
                  <p className="text">We embrace a culture of curiosity and creativity, constantly seeking new and better ways to solve challenges through biotechnological advancements.</p>
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      
    </>
  )
}
