import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';

export default function Allcategory() {
    const { category } = useParams();
    const location = useLocation();
    const [pdfPath, setPdfPath] = useState(null);



    const [cate, setCate] = useState([])
    const [products, setProducts] = useState([])
    const allcategory = () => {
        fetch('https://dashboard.biomaxbiotechnics.in/api/allCategories').then((res) => res.json())
            .then((result) => {
                setCate(result.cat)

            })
            .catch((err) => {
                console.log(err)
            })

    }

    const allproducts = () => {
        fetch(`https://dashboard.biomaxbiotechnics.in/api/allProducts/${category}`).then((res) => res.json())
            .then((result) => {
                setProducts(result.pro)

            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        allcategory()
        allproducts()
    }, [category])




    useEffect(() => {
        // Extract the last part of the URL
        const currentPath = location.pathname.split("/").pop();
    
        // Define PDF mapping based on the URL
        const pdfMap = {
          gynee: "/pdfs/gynae.pdf",
          pediatric: "/pdfs/pedia.pdf",
          ortho: "/pdfs/ortho_pdf.pdf",
          cardiac: "/pdfs/cardiac.pdf",
          "cough-syrup": "/pdfs/cough_syrup.xls",
        };
    
        // Set PDF path if it exists in the mapping
        if (pdfMap[currentPath]) {
          setPdfPath(pdfMap[currentPath]);
        } else {
          setPdfPath(null);
        }
      }, [location.pathname]);

    return (
        <>

            <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
                <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
                <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li className="dropdown"><Link to="/">Home</Link></li>
                            <li>{category}</li>
                        </ul>
                        <h1>{category}</h1>
                    </div>
                </div>
            </section>

            <div class="container mt-30">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="sidebar">

                            <div class="all_categorys">
                                <h4>Dosage Form</h4>
                                <ul>
                                    <li><Link to="/tablets">Tablets</Link></li>
                                    <li><Link to="/syrup">Syrup</Link></li>
                                    <li><Link to="/suspension">Suspension</Link></li>
                                    <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                    <li><Link to="/injections">Injections</Link></li>
                                    <li><Link to="/respules">Respules</Link></li>
                                    <li><Link to="/ivee">Ivee</Link></li>
                                    <li><Link to="/sachet">Sachet</Link></li>
                                    <li><Link to="/drops">Drop</Link></li>
                                    <li><Link to="/gels">Gels</Link></li>
                                    <li><Link to="/oil">Oil</Link></li>
                                    <li><Link to="/powder">Powder</Link></li>
                                    <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                    <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                                </ul>
                            </div>

                            <div class="all_categorys">
                                <h4>Therapeutic wise</h4>
                                <ul>
                                    <li><Link to="/anti-allergy">Anti Allergy</Link></li>
                                    <li><Link to="/laxative">Laxative</Link></li>
                                    <li><Link to="/ortho">Ortho</Link></li>
                                    <li><Link to="/gastro">Gastro</Link></li>
                                    <li><Link to="/pediatric">Pediatric</Link></li>
                                    <li><Link to="/anti-diarreahea">Anti diarreahea</Link></li>
                                    <li><Link to="/dental">Dental</Link></li>
                                    <li><Link to="/respiratory">Respiratory</Link></li>
                                    <li><Link to="/antacid">Antacid</Link></li>
                                    <li><Link to="/gynee">Gynee</Link></li>
                                    <li><Link to="/cardiac">Cardiac</Link></li>
                                    <li><Link to="/diabetic">Diabetic</Link></li>
                                    <li><Link to="/neuro">Neuro</Link></li>
                                    <li><Link to="/urology">Urology</Link></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div class="col-lg-9">
                        <div class="products table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>IMAGE</th>
                                        <th>NAME</th>
                                        <th>COMPOSITION</th>
                                        <th>PACKING</th>
                                        <th>VIEW</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        products.map((pros) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Popup trigger={<i class="fa fa-search cursomer" aria-hidden="true"></i>} position="right center">
                                                            <div className='popupimage'> {pros.image ? (  

                                                            <img src={`https://biomaxdashboard.redniruscare.com/${pros.image}`} />
                                                            
                                                            ) : (<img src="/assets/images/istockphoto-1075374570-612x612.jpg" />)}</div>
                                                        </Popup>

                                                       

                                                    </td>
                                                    <td>{pros.name}</td>
                                                    {/* <td>{pros.name}</td> */}
                                                    <td>{pros.composition}</td>
                                                    <td>{pros.packing}</td>
                                                    <td><Link to={`/product/${pros.slug}`} >View</Link></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>


                            {pdfPath ? (
        <a href={pdfPath} download>
            <div className='product_list my-5' style={{display:'flex' , justifyContent:'center'}}>
                  <button className='btn btn-danger px-5 py-2'>Download pdf</button>
             </div>
        </a>
      ) : (
        <p></p>
      )}


                        

                            
                        </div>
                    </div>

                </div>
            </div>






        </>
    )
}
