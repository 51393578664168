import React from 'react';
import '../List/Approved.css';



const Approved = () => {
  return (
    <>

<section
      className="page-title-section p_relative text-center"
      style={{ backgroundImage: `url("/assets/images/SHKAS.webp")` }}
    >
      <div
        className="shape-1"
        style={{ backgroundImage: `url("/assets/images/shape/shape-34.png")` }}
      ></div>
      <div
        className="shape-2"
        style={{ backgroundImage: `url("/assets/images/shape/shape-35.png")` }}
      ></div>
      <div className="auto-container">
        <div className="content-box">
          <ul className="bread-crumb clearfix">
            <li className="dropdown">
              <a href="/">Home</a>
            </li>
            <li>Approved-list</li>
          </ul>
          <h1>Approved-list</h1>
        </div>
      </div>
    </section>


    <div class="container">
   <div class="row justify-content-center">
      <div class="col-lg-12">
         <div class="products table-responsive">
            <table class="tableizer-table">
               <thead>
                  <tr>
                     <th>S.No</th>
                     <th>Composition</th>
                     <th>Pack</th>
                     <th>Packing</th>
                  </tr>
               </thead>
               <tbody>
                  <tr class="tableizer-firstrow">
                     <th></th>
                     <th>ANTI-BIOTIC PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>1</td>
                     <td>Artemether 40 / 80 mg + Lumefantrine 240 / 480 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>2</td>
                     <td>Azithromycin 250 mg Tablet</td>
                     <td>2x5x6</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>3</td>
                     <td>Azithromycin 500 mg Tablet</td>
                     <td>2x5x3</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>4</td>
                     <td>Clarithromycin 250 / 500 mg Tablet</td>
                     <td>10x6</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>5</td>
                     <td>Clindamycin Hydrochloride 300 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>6</td>
                     <td>Doxycycline 100 mg + Lactic Acid Bacillus 5 million spores Capsule</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>7</td>
                     <td>Levooxacin 250 / 500 mg Tablet</td>
                     <td>10x5</td>
                     <td>Alu/Blister</td>
                  </tr>
                  <tr>
                     <td>8</td>
                     <td>Linezolid 600 mg Tablet</td>
                     <td>10x4</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>9</td>
                     <td>Rifaximin 200 / 400 / 550 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>10</td>
                     <td>Moxioxacin 400 mg Tablet</td>
                     <td>10x5</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>11</td>
                     <td>Ooxacin 200 mg + Ornidazole 500 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>12</td>
                     <td>Ooxacin 200 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>MUSCULO-SKELETAL PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>13</td>
                     <td>Aceclofenac 100 mg + Paracetamol 325 mg + Serratiopeptidase 15 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>14</td>
                     <td>Aceclofenac 200 mg SR Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>15</td>
                     <td>Acelofenac 100 mg + Paracetamol 325 mg + Chlorzoxazone 250 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>16</td>
                     <td>Acelofenac 100 mg + Paracetamol 325 mg + Thiocolchicoside 4 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>17</td>
                     <td>Acelofenac 100 mg +Thiocolchicoside 4 / 8 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>18</td>
                     <td>Diacerein 50 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>19</td>
                     <td>Diclo Potassium 50 mg + Paracetamol 325 mg + Serratiopeptidase 15 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>20</td>
                     <td>Diclo Potassium 50 mg + Serratiopeptidase 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>21</td>
                     <td>Etodolac 400 mg + Thiocolchicoside 4 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>22</td>
                     <td>Drotaverine 80 mg + Mefenamic Acid 250 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>23</td>
                     <td>Etodolac 400 / 600 mg extended Release Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>24</td>
                     <td>Lornoxicam 4 / 8 mg + Thiocolchicoside 4 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>25</td>
                     <td>Lornoxicam 8 mg + Paracetamol 325 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>26</td>
                     <td>Tolperisone HCl 150/450 mg (SR) Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>27</td>
                     <td>Tolperisone HCl 150 mg + Diclofenac sodium 50 mgTablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>28</td>
                     <td>Thiocochicoside 4 / 8 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>29</td>
                     <td>Trypsin 48 mg + Bromelain 90 mg + Rutoside 100 mg + Diclofenac 50 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>30</td>
                     <td>Trypsin-Chymotrypsin 100,000 Armour Units Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>ORTHOPEDIC &amp; ARTHRITIS PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>31</td>
                     <td>Hydroxychloroquine Sulphate 200 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>32</td>
                     <td>Glucosamine 750 mg + MSM 250 mg + Diacerein 50 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>33</td>
                     <td>Etoricoxib 60 /90 /120 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>34</td>
                     <td>Etoricoxib 60 mg + Paracetamol 325 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>35</td>
                     <td>Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>36</td>
                     <td>Piroxicam 20 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>37</td>
                     <td>Tofacitinib 5 mg / 11 mg ER Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>38</td>
                     <td>Diclofenac 50 mg + Metaxalone 400 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>ANTI-ALLERGIC, ANTI-HISTAMINE &amp; PULMONARY PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>39</td>
                     <td>Acebrophylline 100 mg Capsule / 200 mg SR Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>40</td>
                     <td>Acebrophylline 100 mg + Acetylcysteine 600 mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>41</td>
                     <td>Bilastine 20 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>42</td>
                     <td>Bilastine 20 mg + Montelukast 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>43</td>
                     <td>Deazacort 6/30 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>44</td>
                     <td>Desloratadine 5 mg + Montelukast 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>45</td>
                     <td>Desloratadine 5 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>46</td>
                     <td>Fexofenadine 120 mg + Montelukast 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>47</td>
                     <td>Levocetirizine 2.5 / 5 mg + Montelukast 4 / 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>48</td>
                     <td>Levocetirizine 5 mg + Montelukast 10 mg + Ambroxol (SR) 75 mg (Bilayered)</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>49</td>
                     <td>Levocetirizine 5 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>50</td>
                     <td>Methyl Prednisolone 4 / 8 / 16 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>51</td>
                     <td>Acebrophylline 200 mg+Fexofenadine HCL 120 Mg+Montelukast 10Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>MIGRAINE PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>52</td>
                     <td>Flunarizine Dihydrochloride 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>53</td>
                     <td>Flupirtine Maliate 100 mg + Paracetamol 325 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>54</td>
                     <td>Paracetamol 325 mg + Domperidone 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>55</td>
                     <td>Naproxen Sodium 250 / 500 mg + Domperidone Maleate 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>56</td>
                     <td>Naproxen 500 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>57</td>
                     <td>Cinnarizine 20 Mg + Domperidone 15 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>METABOLISM PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>58</td>
                     <td>Febuxostat 40 / 80 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>59</td>
                     <td>Ursodeoxycholic Acid 150 / 300 / 600 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>60</td>
                     <td>Allopurinol 100 / 300 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr></tr>
                  <tr>
                     <th></th>
                     <th>ALIMENTARY PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>61</td>
                     <td>Esomeprazole 40 mg + Domperidone 30 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>62</td>
                     <td>Esomeprazole 40 mg + Levosulpiride 75 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>63</td>
                     <td>Pantoprazole 40 mg + Domperidone 30 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>64</td>
                     <td>Pantoprazole 40 mg + Levosulpiride 75 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>65</td>
                     <td>Rabeprazole 20 / 40 mg + Domperidone 30 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>66</td>
                     <td>Rabeprazole 20 mg + Itopride 150 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>67</td>
                     <td>Rabeprazole 20 mg + Levosulpiride 75 mg SR Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>HEPATOPROTECTIVE &amp; NUTRITIONAL PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>68</td>
                     <td>"Ferrous Bis-Glycinate 60 mg + Zinc Bis-Glycinate 15 mg + Folic 1 mg +</td>
                  </tr>
                  <tr>
                     <td>69</td>
                     <td>Mecobalamin 500 mcg (Tablet)"</td>
                     <td>10x15</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>70</td>
                     <td>Benfotiamine 150 mg + Methylcobalamin 1500 Mcg + Alpha Lipoic Acid 100 Mg + Inositol 100 Mg + Folic Acid 1.5Mg + Chromium Picolinate 200Mcg + Selenium Dioxide 55 mcg</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>71</td>
                     <td>Orlistat 60 / 120 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>CENTRAL NERVOUS SYSTEM &amp; PSYCHIATRY PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>72</td>
                     <td>Aceclofenac (SR) 200 mg + Pregabalin (SR) 75 mg (Bilayered) Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>73</td>
                     <td>Citicoline Sodium 500 mg + Piracetam 400/800 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>74</td>
                     <td>Citicoline Sodium 500 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>75</td>
                     <td>Gabapentin 400 mg + Nortryptiline 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>76</td>
                     <td>Gabapentin 300 mg + Methylcobalamin 500 mcg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>77</td>
                     <td>Levetiracetam 250 / 500 / 750 / 1000 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>78</td>
                     <td>Levosulpiride 25 mg Taablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>79</td>
                     <td>Pregabalin 75 mg + Nortryptiline 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>80</td>
                     <td>Pregabalin 75 mg (PR) + Nortryptiline 10 mg + Mecobalamin 1500 mcg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>81</td>
                     <td>Pregabalin 75 mg SR+ Methylcobalamin 1500 mcg Bilayered Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>82</td>
                     <td>Pregabalin 300/75 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>83</td>
                     <td>Pregabalin 75 mg + Methylcobalamin 750 mcg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>GYNAECOLOGY PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>84</td>
                     <td>Doxylamine Succinate 10 mg + Pyridoxine 10 mg + Folic Acid 2.5 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>85</td>
                     <td>Isoxsuprine Hydrochloride (SR) 40 mg</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>86</td>
                     <td>Mefenamic Acid 250 mg + Dicyclomine 10mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>87</td>
                     <td>Kit of Azithromycin 1gm + Secnidazole 1 gm + Fluconazole 150 mg</td>
                     <td>1X4</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>88</td>
                     <td>Tranexamic Acid 250 / 500 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>89</td>
                     <td>Tranexamic Acid 250 + Ethamsylate 250 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu/Blister</td>
                  </tr>
                  <tr>
                     <td>90</td>
                     <td>Tranexamic Acid 500 mg + Mefenamic Acid 250 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu/Blister</td>
                  </tr>
                  <tr></tr>
                  <tr>
                     <th></th>
                     <th>UROLOGY PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>91</td>
                     <td>Sidenal 50 Mg + Dapoxetine 30 Mg</td>
                     <td>10x4</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>92</td>
                     <td>Flavoxate 200 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>93</td>
                     <td>Flavoxate 200 mg + Ooxacin 200 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>94</td>
                     <td>Nitrofurantoin (SR) 100 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>95</td>
                     <td>Sildenal Citrate eq to Sildenal 50 / 100 mg Tablet</td>
                     <td>10x4</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>96</td>
                     <td>Silodosin 4 / 8 mg Capsule</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>97</td>
                     <td>Silodosin 8 mg + Dutasteride 0.5 mg Capsule (Tablet in Capsule)</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>98</td>
                     <td>Solifenacin 5 / 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>99</td>
                     <td>Tamsulosin 0.4 mg + Dutasteride 0.5 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>100</td>
                     <td>Torsemide 5 / 10 / 20 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>CARDIOVASCULAR PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>101</td>
                     <td>Losartan 50 Mg + Hydrochlorothiazide 12.5 Mg</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>102</td>
                     <td>Amlodipine 5 Mg +Atenolol 50 mg</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>103</td>
                     <td>Rosuvastatin Calcium 10/20 Mg</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>104</td>
                     <td>Rosuvastatin 10 Mg+Asprin 75 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>105</td>
                     <td>Rosuvastatin 10 Mg+Fenobrate 160mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>106</td>
                     <td>Metoprolol 25/50 Mg ER Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>107</td>
                     <td>Olmesartan 20/40 MG Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>108</td>
                     <td>Olmesartan 20/40n Mg + HCTZ 12.5Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>109</td>
                     <td>Cilnidipine 10 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>110</td>
                     <td>Atorvastatin 10/20 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>111</td>
                     <td>Atorvastatin 10Mg + Fenobrate 160Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>112</td>
                     <td>Telmisartan 40 MgTablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>113</td>
                     <td>Telmisartan 40Mg + Amlodipine 5 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>114</td>
                     <td>Telmisartan 40 Mg +Chlorthalidone 12.5 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>115</td>
                     <td>Telmisartan 40/80Mg + HCTZ 12.5Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>116</td>
                     <td>Ranolazine 500 Mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>117</td>
                     <td>Ticagerol 90 mg Tablet</td>
                     <td>1x14</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>118</td>
                     <td>Amlodipine Besylate 2.5/5/10 mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>119</td>
                     <td>Amlodipine 5 mg + Metoprolol 50 mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr>
                     <td>120</td>
                     <td>Amlodipine 5 mg + Olmesartan Medoximil 20mg +Hydrocglorothiazide 12.5 mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Strip</td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                  <tr>
                     <th></th>
                     <th>DIABETIC PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>121</td>
                     <td>Dapagliozin 5 / 10 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>122</td>
                     <td>Metformin HCL 500 mg (ER) + Glimepride 1 / 2 mg Tablet</td>
                     <td>10x14</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>123</td>
                     <td>Metformin HCL 500 mg (ER) + Glimepride 1 / 2 mg + Pioglitazone 15 mg Tablet (Bilayered Tablet)</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>124</td>
                     <td>Metformin HCL 1000 mg (ER) + Glimepride 1 / 2 / mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>125</td>
                     <td>Dapagliozin 10 mg + Metformin 500 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>126</td>
                     <td>Sitagliptin 50 / 100 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>127</td>
                     <td>Sitagliptin 50 mg + Metformin 500 / 1000 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>128</td>
                     <td>Teneligliptin 20mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>129</td>
                     <td>Teneligliptin 20mg + Metformin 500 / 1000 mg (ER) Tablet (Bilayered)</td>
                     <td>10x15</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>130</td>
                     <td>Vildagliptin 50mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>131</td>
                     <td>Vildagliptin 50mg + Metformin 500 / 1000 mg Tablet</td>
                     <td>10x15</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>132</td>
                     <td>Voglibose 0.2 / 0.3 mg + Metformin 500 mg (Bilayered)</td>
                     <td>10x15</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>133</td>
                     <td>Voglibose 0.2 / 0.3 mg + Metformin 500 mg + Glimepiride 1 / 2 mg (Bilayered)</td>
                     <td>10x15</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>SKIN &amp; OPTHALMIC PRODUCTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>134</td>
                     <td>Albendazole 400 mg + Ivermectin 6 mg Tablet</td>
                     <td>10x10x1</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>135</td>
                     <td>Fluconazole 150 / 200 / 300 mg Tablet</td>
                     <td>20x5</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>136</td>
                     <td>Hydroxyzine 10 / 25 mg Tablet</td>
                     <td>10x10/10x15</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>137</td>
                     <td>Itraconazole 100 / 200 mg Capsule</td>
                     <td>10x4/10x10</td>
                     <td>Blister/Alu</td>
                  </tr>
                  <tr>
                     <td>138</td>
                     <td>Ivermectin 12 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>139</td>
                     <td>Terbinane HCL 250 / 500 mg Tablet</td>
                     <td>10x7</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>APPROVAL OF UNIT II</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>140</td>
                     <td>Cexime 200mg Dispersible Tablets IP</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>141</td>
                     <td>Cexime 200 Film Coated Tablets IP</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>142</td>
                     <td>Cefpodoxime 200mg &amp; Ooxocin 200mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>143</td>
                     <td>Cexime 200mg &amp; Lactic acid Bacillus 60 Million Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>144</td>
                     <td>Cefpodoxime Proxetil Dispersible 200mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>145</td>
                     <td>Cefuroxime Axetil 250mg Tablets IP</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>146</td>
                     <td>Cefuroxime Axetil 500mg Tablets IP</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>147</td>
                     <td>Cexime 200mg &amp; Ooxacin 200mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>148</td>
                     <td>Faropenem Sodium 200mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>149</td>
                     <td>Faropenem Sodium 300mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>150</td>
                     <td>Sultamicillin 375mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr></tr>
                  <tr>
                     <th></th>
                     <th>ANTICOLD PRODCUTS</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>151</td>
                     <td>Dextromethorphan 10 mg + Phenylephrine 5 mg + CPM 2 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>152</td>
                     <td>Paracetamol 500 mg + Phenylephrine HCl 10 mg + CPM 2mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>153</td>
                     <td>Paracetamol 500 mg + Phenylephrine 5 mg+ Caffeine 30mg + Diphenhydramine 25 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>154</td>
                     <td>Aceclofenac 100 mg + PCM 325 mg+ Phenylephrine 5 mg+Caffeine 30mg +Citrizine 25 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>155</td>
                     <td>"Aceclofenac 100 mg + PCM 325 mg+ Phenylephrine 5 mg+Caffeine 30mg +Citrizine 25 mg Tablet</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <th></th>
                     <th>ALSO AVAILABLE</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td>155</td>
                     <td>Granisetron 1 mg Tablets</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>156</td>
                     <td>Betahistine Dihydrochlored 8/16/24/32 mg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>157</td>
                     <td>Ferrous Ascorbate 100 Mg + Folic Acid 1.5 Mg+Methycobalamin 1500 mcg Tablet</td>
                     <td>10x10</td>
                     <td>Alu-Alu</td>
                  </tr>
                  <tr>
                     <td>158</td>
                     <td>Tapentadol 50/100 Mg Tablets</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
                  <tr>
                     <td>159</td>
                     <td>Paracetamol 300 Mg (As Immediate Release) + Paracetamol 700 Mg ( As Sustained Release ) Tablets</td>
                     <td>10x10</td>
                     <td>Blister</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </div>
</div>
    </>
  )
}

export default Approved