import React from 'react'
import { Link } from 'react-router-dom'

export default function Contactscreen() {
  return (
    <>
	<section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>Contact us</li>
            </ul>
            <h1>Contact us</h1>
          </div>
        </div>
      </section>
	  <section className="contact-section two sec-pad">
    <div className="auto-container">
        <div className="row">
            <div className="col-xl-7 col-md-6">
                <div className="title-box text-start mb_30">
                    <h5 className="sec-sub-title">contact us</h5>
                    <div className="line-box">
                        <span className="line-1"></span>
                        <span className="line-2"></span>
                        <span className="line-3"></span>
                    </div>
                    <h2 className="sec-title">Ready to get started?</h2>
                    <p className="text">Your email address will not be published. Required fields are <br />marked *</p>
                </div>
                <div className="form-inner">
                    <form  className="default-form"> 
                        <div className="row clearfix">
                            <div className="col-xl-12 form-group">
                                <input type="text" name="username" placeholder="Your Name" />
                            </div>
                            <div className="col-xl-12 form-group">
                                <input type="email" name="email" placeholder="Your Email *" required="" />
                            </div>
                            <div className="col-xl-12 form-group">
                                <input type="text" name="phone" placeholder="Your Phone" />
                            </div>
                            <div className="col-xl-12 form-group">
                                <textarea name="message" placeholder="Message"></textarea>
                            </div>
                            <div className="col-xl-12 form-group message-btn centred">
                                <button className="theme-btn btn-one" type="submit" name="submit-form">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-xl-5 col-md-6">
                <div className="title-box text-start mb_30">
                    <h5 className="sec-sub-title">Our Location</h5>
                    <div className="line-box">
                        <span className="line-1"></span>
                        <span className="line-2"></span>
                        <span className="line-3"></span>
                    </div>
                    <h2 className="sec-title">Get in touch</h2>
                    <p className="text pr_90">A leading manufacturer and supplier of DCGI Approved formulations such as tablets, injections and new drugs, Biomax Biotechnics Pvt. Ltd. </p>
                </div>
                <div className="info-box">
                  
                    <div className="icon-box">
                        <div className="icon-1">
                            <i className="fa fa-map-marker-alt"></i>
                        </div>
                        <div className="info-text">
                            <h4>Our Address</h4>
                            <p>Plot No 261 Industrial Estate Alipur Barwala Panchkula</p>
                        </div>
                    </div>
                   
                    <div className="icon-box">
                        <div className="icon-1">
                            <i className="fa fa-envelope-open"></i>
                        </div>
                        <div className="info-text">
                            <h4>Contact Number</h4>
                            <ul className="phone-number">
                                <li><Link to="tel:(+44)457895789">Mobile: +(91)-81466 74934 </Link></li>
                                <li><Link to="tel:(+44)457899988">Mobile: +(91)-90238 22204</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="icon-box">
                        <div className="icon-1">
                            <i className="fa fa-phone-volume"></i>
                        </div>
                        <div className="info-text">
                            <h4>Email Address</h4>
                            <ul className="email-add">
                                <li><Link to="mailto:info@valorwide.com"> care@biomaxbiotechnics.in </Link></li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="map-section">
    <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d184757.50505820595!2d76.77434143878841!3d30.6326630958266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d30.6774016!2d76.84096!4m5!1s0x390f9331ad749419%3A0x265c0b0e6f7b1976!2sbiomax%20biotechnics!3m2!1d30.55679!2d76.9398629!5e0!3m2!1sen!2sin!4v1691392349232!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
	</>
  )
}
