import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Blog = () => {
  const [blog, setBlog] = useState([]); // ✅ Initialize as an array
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://dashboard.biomaxbiotechnics.in/api/blogs`)
      .then((res) => res.json())
      .then((result) => {
        if (result.success && Array.isArray(result.blogs)) {
          setBlog(result.blogs); // ✅ Use the correct response key
          
        } else {
          console.log("Invalid response format:", result);
        }
      })
      .catch((err) => {
        console.log("Fetch error:", err);
      });
  }, []);


  const handleClick = (name, description, image , created_at , slug) => {
    // Construct the data dynamically from the parameters
    const dynamicData = { name, description, image , created_at };

    // Pass the data with navigation
    navigate(`/blog-details/${slug}`, { state: dynamicData });
  };



  return (
    <>
      <section
        className="page-title-section p_relative text-center"
        style={{ backgroundImage: `url("/assets/images/SHKAS.webp")` }}
      >
        <div
          className="shape-1"
          style={{ backgroundImage: `url("/assets/images/shape/shape-34.png")` }}
        ></div>
        <div
          className="shape-2"
          style={{ backgroundImage: `url("/assets/images/shape/shape-35.png")` }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown">
                <a href="/">Home</a>
              </li>
              <li>Blog</li>
            </ul>
            <h1>Blog</h1>
          </div>
        </div>
      </section>

      <div className="container my-5">
        <div className="row">
          {blog.length > 0 ? (
            blog.map((data) => (
              <div className="col-lg-4" key={data.id}>
                <div className='border p-2'>
               
                <img 
                    src={`https://dashboard.biomaxbiotechnics.in/public/images/blog/${data?.image}`} 
                    alt={data?.name || 'Blog Image'} 
                    style={{ width: '100%', height: 'auto' }} />
                    <hr></hr>

                <h4> {data.name}</h4>
                <p className='text-left'> {new Date(data.created_at).toLocaleDateString('en-GB', {  
                        day: '2-digit',  
                        month: 'long',  
                        year: 'numeric'  
                    })}  
                </p>

                <div>
                <button className='px-5 py-2 btn btn-primary'
        onClick={() => handleClick(data.name , data.description , data.image , data.created_at , data.slug)}>
        View
      </button>
                </div>
              

                
                
               </div>
                



              </div>
            ))
          ) : (
            <p>No blogs available</p> // ✅ Show message when no blogs
          )}
        </div>
      </div>
    </>
  );
};

export default Blog;
