import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Owldemo1() {
  return (
    <div>
      <div>  
         
       <div class='container-fluid' >            
        <OwlCarousel  
          className="owl-theme"  
          items={1} margin={8} autoplay ={true} >  
           <div ><img  className="img" src= {'/assets/images/biomax.jpg'}/></div>  
           <div><img  className="img" src= {'/assets/images/banner-one.jpg'}/></div>  
          
      </OwlCarousel>  
      </div>  
  
      </div>
    </div>
  )
}
