import React from 'react'
import '../Videos/Videos.css'


const Video = () => {
  return (
    <>

<section
        className="page-title-section p_relative text-center"
        style={{ backgroundImage: `url("/assets/images/SHKAS.webp")` }}
      >
        <div
          className="shape-1"
          style={{ backgroundImage: `url("/assets/images/shape/shape-34.png")` }}
        ></div>
        <div
          className="shape-2"
          style={{ backgroundImage: `url("/assets/images/shape/shape-35.png")` }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown">
                <a href="/">Home</a>
              </li>
              <li>Videos</li>
            </ul>
            <h1>Videos</h1>
          </div>
        </div>
      </section>

      <div className='container'>
        <div className='row'>
            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid1.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid2.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid3.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid4.mp4' type="video/mp4" />
            </video>
            </div>


            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid5.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid6.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid7.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid8.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid9.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid10.mp4' type="video/mp4" />
            </video>
            </div>

            <div className='col-md-4'>
            <video width="320" height="240" controls>
            <source src='assets/videos/vid11.mp4' type="video/mp4" />
            </video>
            </div>


        </div>




      </div>
    
    
    </>
  )
}

export default Video